export const selector = '.tags[data-paginate]'

export function hydrate (element) {
  const tags = element.querySelectorAll('.tag')
  const moreButton = element.querySelector('button[data-action=more]')
  const offset = element.dataset.paginate === 'auto'
    ? Array.from(tags).filter((tag, index) => {
      // Skipping first two items to make place for the more button
      return index > 1 && element.offsetTop === tag.offsetTop
    }).length || tags.length
    : +element.dataset.paginate

  if (offset >= tags.length - 1) return moreButton.remove()

  let shown = false
  moreButton.addEventListener('click', toggle)
  hide()

  function toggle () {
    shown ? hide() : show()
    shown = !shown
  }

  function show () {
    moreButton.innerText = '-'
    for (let index = offset; index < tags.length; index++) {
      if (tags[index].classList.contains('is-active')) continue
      tags[index].style.display = ''
    }
  }

  function hide () {
    moreButton.innerText = '+'
    for (let index = offset; index < tags.length; index++) {
      if (tags[index].classList.contains('is-active')) continue
      tags[index].style.display = 'none'
    }
  }
}
