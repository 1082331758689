import JSZip from 'jszip'

// SEE https://stuk.github.io/jszip/
export default async function zip (files = new Map(/* filename: <Blob> */)) {
  const zip = new JSZip()

  for (const [filename, blob] of files) {
    zip.file(filename, blob)
  }

  return zip.generateAsync({ type: 'blob' })
}
