export const selector = '.filters'

export function hydrate (element) {
  // Allow toggling input[radio] by clicking its label
  for (const label of element.querySelectorAll('label')) {
    const input = document.getElementById(label.getAttribute('for'))
    if (!input) continue

    input.addEventListener('change', e => {
      label.classList.toggle('is-open', input.checked)
    })

    label.addEventListener('click', e => {
      e.preventDefault()
      input.checked = !input.checked

      for (const candidate of element.querySelectorAll('label')) {
        candidate.classList.toggle('is-open', candidate === label && input.checked)
      }
    })
  }

  // Filter search
  const filter = element.querySelector('input[type=search]')
  if (filter) {
    const items = Array.from(document.querySelectorAll('[data-filterable]'))

    const sanitize = (string, { uppercase = false } = {}) => {
      string = string
        .replace(/\u00a0/g, ' ')
        .replace(/\s+/g, ' ')
        .replace(/’/g, "'")

      if (string === ' ') return
      return uppercase ? string.toUpperCase() : string
    }

    // Populate data-filter
    for (const item of items) {
      item.dataset.filter = sanitize(item.innerText, { uppercase: true })
    }

    filter.addEventListener('input', e => {
      const needle = sanitize(filter.value, { uppercase: true })

      for (const item of items) {
        const match = needle && item.dataset.filter.includes(needle)
        item.style.display = (!needle || needle === '' || match) ? '' : 'none'

        for (const text of item.querySelectorAll('h3, .tag, td a, .card__text, .label')) {
          if (!text.dataset.withoutMark) text.dataset.withoutMark = text.innerText
          text.innerHTML = text.dataset.withoutMark
          if (!match) continue

          text.innerHTML = sanitize(text.innerText).replace(new RegExp(needle, 'gi'), '<mark>$&</mark>')
        }
      }
    })
  }
}
