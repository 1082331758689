import { debounce } from 'debounce'
export const selector = '.cards[data-scrollable]'

const THRESHOLD = 20 // px

export function hydrate (element) {
  const container = element.querySelector('.cards__content')
  const nextButton = element.querySelector('button[data-action=next]')
  const prevButton = element.querySelector('button[data-action=previous]')

  let enabled = true
  const items = Array.from(element.querySelectorAll('.cards__content > .card'))

  const init = () => {
    element.setAttribute('data-scrollable', 1)
    window.requestAnimationFrame(() => {
      if (container.scrollWidth <= container.clientWidth) {
        element.removeAttribute('data-scrollable')
      }
    })
  }

  init()
  window.addEventListener('resize', init)
  prevButton.addEventListener('click', prev)
  nextButton.addEventListener('click', next)

  const enable = debounce(() => { enabled = true }, 100)

  // Update prev/next buttons state based on current scroll
  container.addEventListener('scroll', e => {
    disable()
    window.requestAnimationFrame(() => {
      prevButton.classList.toggle('is-disabled', container.scrollLeft <= THRESHOLD)
      nextButton.classList.toggle('is-disabled', Math.abs(container.scrollLeft) >= container.scrollWidth - container.clientWidth - THRESHOLD)
    })
  })

  function disable () {
    enabled = false
    enable()
  }

  function prev () {
    if (!enabled) return
    const prevItem = items.findLast(item => item.offsetLeft < container.scrollLeft)
    prevItem && prevItem.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    })
  }

  function next () {
    if (!enabled) return
    const nextItem = items.find(item => item.offsetLeft + item.clientWidth - THRESHOLD > container.clientWidth + container.scrollLeft)
    nextItem && nextItem.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    })
  }
}
