export const selector = '.menu'

export function hydrate (element) {
  // Focus search input on open and bind ESC key to close

  const toggleMenu = document.getElementById('toggleMenu')
  const toggleSearch = document.getElementById('toggleSearch')
  const searchInput = document.querySelector('.search input[type=search]')

  function captureEsc (e) {
    if (e.key === 'Escape') toggleSearch.checked = false
  }

  // Sync menu and search state for mobile
  toggleSearch.addEventListener('change', () => { toggleMenu.checked = toggleSearch.checked })
  toggleMenu.addEventListener('change', () => { toggleSearch.checked = toggleMenu.checked })

  toggleSearch.addEventListener('change', e => {
    if (!toggleSearch.checked) {
      searchInput.blur()
      window.removeEventListener('keyup', captureEsc)
    } else {
      searchInput.focus()
      searchInput.select()
      window.addEventListener('keyup', captureEsc)
    }
  })
}
