// All-purpose logger

function noop () {}
const noopObj = { log: noop, warn: noop, error: noop }
let logger = () => noopObj

///////////////////
////////////////////////////////////////////////////////////
//////////////////////////

////////////////
///////////////////////////////

///////////////////////////
/////////////////////////////////
///////////////////////////////////////
///////////////////////////////////////////////////////////
/////////////////

//////////
///////////////////////////////////////////
/////////////////////////////////////////////
//////////////////////////////////////////////
///
 
//////////

export default logger
