/* global Blob */

import FileSaver from 'file-saver'

export default function (filename, { blob, url, json } = {}) {
  if (url) {
    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return
  }

  if (json) {
    const string = JSON.stringify(json, null, window.ENV.production ? 0 : 2)
    blob = new Blob([string], { mimetype: 'application/json' })
  }

  return FileSaver.saveAs(blob || url, filename)
}
